
/* @font-face {
    font-family: 'CustomFont';
    src: url('../assets/fonts/BloodyScene-DYOZm.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  } */
  
  /* body {
    font-family:  sans-serif,'CustomFont';
  } */

  body {
    overflow-x: hidden;
  }
  
  .underline-animation {
    position: relative;
    display: inline-block;
  }
  
  .underline-animation::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Positioning just below the text */
    width: 100%;
    height: 2px; /* Thickness of the underline */
    background-color: orange;
    transform: scaleX(0); /* Start with no width */
    transform-origin: left; /* Animate from left to right */
    transition: transform 0.4s ease;
  }
  
  .underline-animation:hover::after {
    transform: scaleX(1); /* Full width on hover */
  }
  

  .underline-animation-new {
    position: relative;
    display: inline-block;
  }
  
  .underline-animation-new::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px; /* Positioning just below the text */
    width: 100%;
    height: 2px; /* Thickness of the underline */
    background-color: rgb(25, 0, 255);
    transform: scaleX(0); /* Start with no width */
    transform-origin: left; /* Animate from left to right */
    transition: transform 0.4s ease;
  }
  
  .underline-animation-new:hover::after {
    transform: scaleX(1); /* Full width on hover */
  }



  /* Custom Arrow Styles */
.custom-next-arrow,
.custom-prev-arrow {
  font-size: 24px;
  color: blue;
  cursor: pointer;
  z-index: 1;
}

/* Add space between the arrows and the slider */
.custom-next-arrow {
  right: -20px; /* Move 20px away from the slider */
}

.custom-prev-arrow {
  left: -20px; /* Move 20px away from the slider */
}

/* Hover Effect */
.custom-next-arrow:hover,
.custom-prev-arrow:hover {
  color: #2563eb; /* Adjust blue hover color */
}


/* Smooth hover effect on the cards */
.bg-white:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Button hover effect */
.bg-blue-600:hover {
  background-color: #2563eb; /* Darker shade of blue */
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}


.custom-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7); /* Darker and more prominent shadow */
}


.underline-animation::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 0;
  background-color: #ffd700;
  transition: width 0.3s ease-in-out;
}
.underline-animation:hover::after {
  width: 100%;
}
